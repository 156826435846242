import React from 'react';
import styled from 'styled-components';

type StyledImgProps = {
  maskUrl?: string;
};

const StyledImg = styled.img<StyledImgProps>`
  width: 100%;
  ${props => props.maskUrl && props.theme.mask(props.maskUrl)}
`;

interface PreviewImgProps {
  src: string;
  maskUrl?: string;
  className?: string;
}

const PreviewImg: React.FC<PreviewImgProps> = props => {
  const { src, maskUrl, className } = props;

  return <StyledImg src={src} maskUrl={maskUrl} className={className} />;
};

export default PreviewImg;
