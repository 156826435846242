import React from 'react';
import styled, { css } from 'styled-components';
import Img from 'gatsby-image';

import PreviewImg from '../shared/PreviewImg';

import borderUrl from '../../assets/images/poster_border.svg';
import creditUrl from '../../assets/images/poster_credit.svg';

const StyledPosterContainer = styled.div`
  position: relative;
  cursor: pointer;
`;

const StyledBorder = styled.div`
  ${props => props.theme.borderMask(borderUrl)};
`;

type StyledImgProps = {
  aspectRatio?: number;
  detailView?: boolean;
};

const StyledImg = styled(Img)<StyledImgProps>`
  ${props =>
    props.detailView
      ? css`
          height: 80vh;
          width: calc(${props.aspectRatio} * 80vh);
        `
      : css`
          height: 23rem;
          width: calc(${props.aspectRatio} * 23rem);

          @media (max-width: ${props => props.theme.breakMedium}) {
            height: 20rem;
            width: calc(${props.aspectRatio} * 20rem);
          }

          @media (max-width: ${props => props.theme.breakSmall}) {
            width: 80vw;
            height: calc(80vw / ${props.aspectRatio});
          }
        `}
  ${props => props.theme.mask(borderUrl)};
`;

const StyledPreviewImg = styled(PreviewImg)`
  height: 23rem;
  width: auto;
`;

const StyledCreditLink = styled.a`
  text-decoration: none;
  color: ${props => props.theme.black};
`;

type StyledCreditBorderProps = {
  position: string;
};

const StyledCreditBorder = styled.div<StyledCreditBorderProps>`
  position: absolute;
  ${props => props.theme.borderMask(creditUrl)};
  ${props => getPositionCSS(props.position)};
`;

const StyledCreditText = styled.span`
  display: inline-block;
  padding: 0.4rem 0.6rem 0.3rem;
  max-width: 15rem;
  background: ${props => props.theme.white};
  font-size: 0.75rem;
  ${props => props.theme.customTextStyle};
  ${props => props.theme.mask(creditUrl)};
`;

const CreditTopLeft = css`
  top: -0.4rem;
  left: -0.2rem;
`;

const CreditTopRight = css`
  top: -0.4rem;
  right: -0.2rem;
`;

const CreditBottomLeft = css`
  bottom: -0.2rem;
  left: -0.1rem;
`;

const CreditBottomRight = css`
  bottom: -0.2rem;
  right: 0;
`;

const getPositionCSS = (position: string) => {
  switch (position) {
    case 'top-left':
      return CreditTopLeft;
    case 'top-right':
      return CreditTopRight;
    case 'bottom-left':
      return CreditBottomLeft;
    case 'bottom-right':
    default:
      return CreditBottomRight;
  }
};

interface PosterProps {
  content: Collections.Poster;
  imgSrc?: { url: string; path: string; field?: any; fileObj: File };
  preview?: boolean;
  detailView?: boolean;
}

const Poster: React.FC<PosterProps> = props => {
  const {
    content: { credit, credit_link, credit_position, main_image },
    imgSrc,
    preview,
    detailView,
  } = props;

  return (
    <StyledPosterContainer>
      <StyledBorder>
        {preview ? (
          <StyledPreviewImg src={imgSrc} maskUrl={borderUrl} />
        ) : (
          <StyledImg
            fluid={{ ...main_image?.childImageSharp.fluid }}
            aspectRatio={main_image?.childImageSharp.fluid.aspectRatio}
            detailView={detailView}
          />
        )}
      </StyledBorder>
      <StyledCreditLink
        href={credit_link}
        target="_blank"
        rel="noopener noreferrer"
      >
        <StyledCreditBorder position={credit_position}>
          <StyledCreditText>
            Poster by <br />
            {credit}
          </StyledCreditText>
        </StyledCreditBorder>
      </StyledCreditLink>
    </StyledPosterContainer>
  );
};

export default React.memo(Poster);
