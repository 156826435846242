import React from 'react';
import MDX from 'mdx-scoped-runtime';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import styled from 'styled-components';
import moment from 'moment';

import borderUrl from '../../assets/images/text_border.svg';
import { ReactComponent as Side } from '../../assets/images/text_side.svg';
import { ReactComponent as Tape } from '../../assets/images/text_tape.svg';
import { ReactComponent as Line } from '../../assets/images/text_line.svg';

const StyledTextContainer = styled.div`
  position: relative;
  width: 21rem;
  white-space: initial;

  @media (max-width: ${props => props.theme.breakMedium}) {
    width: 18rem;
  }

  @media (max-width: ${props => props.theme.breakSmall}) {
    width: 80vw;
  }
`;

const StyledTextBorder = styled.div`
  width: 100%;
  ${props => props.theme.borderMask(borderUrl)};
`;

const StyledText = styled.div`
  width: 100%;
  padding: 2.5rem 2.5rem 2rem;
  background: ${props => props.theme.white};
  ${props => props.theme.mask(borderUrl)};
  ${props => props.theme.customTextStyle};

  @media (max-width: ${props => props.theme.breakMedium}) {
    padding: 2rem 2rem 1.5rem;
  }

  @media (max-width: ${props => props.theme.breakSmall}) {
    padding: 1.5rem 1.5rem 1rem;
  }
`;

const StyledSide = styled(Side)`
  position: absolute;
  top: 1.1rem;
  right: -0.5rem;
  height: 91%;
  width: 1.4rem;
  stroke: ${props => props.theme.black};
  stroke-width: 25;
`;

const StyledTape = styled(Tape)`
  position: absolute;
  top: -0.5rem;
  left: 50%;
  width: 4rem;
  transform: translateX(-50%);
  stroke: ${props => props.theme.black};
  stroke-width: 20;

  @media (max-width: ${props => props.theme.breakMedium}) {
    top: -0.3rem;
    width: 3rem;
  }
`;

const StyledTitle = styled.p`
  margin: 0;
  font-size: 0.875rem;
`;

const StyledDate = styled.span`
  font-size: 0.625rem;
`;

const StyledLine = styled(Line)``;

const StyledContents = styled.div`
  font-size: 0.75rem;
  line-height: 2;
`;

interface TextProps {
  content: Collections.Text;
  preview?: boolean;
}

const Text: React.FC<TextProps> = props => {
  const {
    content: { show, text },
    preview,
  } = props;

  return (
    <StyledTextContainer>
      <StyledSide />
      <StyledTextBorder>
        <StyledText>
          <StyledTitle>{`${show.venue}, ${show.city}`}</StyledTitle>
          <StyledDate>{moment(show.date).format('MMMM D, YYYY')}</StyledDate>
          <StyledLine />
          <StyledContents>
            {preview ? <MDX>{text}</MDX> : <MDXRenderer>{text}</MDXRenderer>}
          </StyledContents>
        </StyledText>
      </StyledTextBorder>
      <StyledTape />
    </StyledTextContainer>
  );
};

export default React.memo(Text);
