import React from 'react';
import styled, { css } from 'styled-components';
import Img from 'gatsby-image';

import PreviewImg from '../shared/PreviewImg';

import frameUrl from '../../assets/images/polaroid_frame.svg';
import innerUrl from '../../assets/images/polaroid_inner.svg';
import tape from '../../assets/images/polaroid_tape.png';

const StyledPolaroidContainer = styled.div`
  position: relative;
`;

const StyledPolaroidBorder = styled.div`
  ${props => props.theme.borderMask(frameUrl)};
`;

const StyledPolaroidFrame = styled.div`
  padding: 1.7rem 1.5rem;
  background: ${props => props.theme.white};
  ${props => props.theme.mask(frameUrl)};

  @media (max-width: ${props => props.theme.breakMedium}) {
    padding: 1.4rem 1.25rem;
  }

  @media (max-width: ${props => props.theme.breakSmall}) {
    padding: 1.3rem 1.15rem;
  }
`;

const StyledPolaroidImgBorder = styled.div`
  ${props => props.theme.borderMask(innerUrl)};
`;

type StyledImgProps = {
  $aspectRatio?: number;
};

const StyledImg = styled(Img)<StyledImgProps>`
  height: 16.6rem;
  width: calc(${props => props.$aspectRatio} * 16.6rem);
  ${props => props.theme.mask(innerUrl)};

  @media (max-width: ${props => props.theme.breakMedium}) {
    height: 14.5rem;
    width: calc(${props => props.$aspectRatio} * 14.5rem);
  }

  @media (max-width: ${props => props.theme.breakSmall}) {
    width: 70vw;
    height: calc(70vw / ${props => props.$aspectRatio});
  }
`;

const StyledPreviewImg = styled(PreviewImg)`
  position: relative;
  height: 16.6rem;
  width: auto;

  @media (max-width: ${props => props.theme.breakMedium}) {
    height: 14.5rem;
  }

  @media (max-width: ${props => props.theme.breakSmall}) {
    width: 70vw;
    height: initial;
  }
`;

const StyledTextContainer = styled.div`
  padding: 1rem 0.5rem 0;

  @media (max-width: ${props => props.theme.breakMedium}) {
    padding: 0.8rem 0.4rem 0;
  }

  @media (max-width: ${props => props.theme.breakSmall}) {
    padding: 0.7rem 0.35rem 0;
  }
`;

type StyledTextProps = {
  $aspectRatio?: number;
};

const StyledText = css<StyledTextProps>`
  display: block;
  ${props => props.theme.customTextStyle};
  ${props => props.theme.ellipsis};

  ${props =>
    props.$aspectRatio &&
    css`
      width: calc(${props.$aspectRatio} * 15.5rem);

      @media (max-width: ${props.theme.breakMedium}) {
        width: calc(${props.$aspectRatio} * 13.7rem);
      }

      @media (max-width: ${props.theme.breakSmall}) {
        width: calc(70vw - 0.7rem);
      }
    `}
`;

const StyledCaption = styled.span<StyledTextProps>`
  ${StyledText};
  margin-bottom: 0.25rem;
  font-size: 0.875rem;
`;

const StyledCredit = styled.a<StyledTextProps>`
  ${StyledText};
  font-size: 0.625rem;
  text-decoration: none;
  color: ${props => props.theme.black};
`;

type StyledTapeProps = {
  $top?: boolean;
};

const StyledTape = styled.img<StyledTapeProps>`
  position: absolute;
  ${props =>
    props.$top
      ? css`
          top: -0.5rem;
          left: -1.2rem;
          transform: rotate(-34deg);
        `
      : css`
          bottom: -0.5rem;
          right: -1rem;
          transform: rotate(146deg);
        `};
  height: 1.6rem;

  @media (max-width: ${props => props.theme.breakMedium}) {
    height: 1.5rem;
  }

  @media (max-width: ${props => props.theme.breakSmall}) {
    height: 1.4rem;
  }
`;

interface PolaroidProps {
  content: Collections.Polaroid;
  imgSrc?: { url: string; path: string; field?: any; fileObj: File };
  preview?: boolean;
}

const Polaroid: React.FC<PolaroidProps> = props => {
  const {
    content: { title, credit, credit_link, main_image },
    imgSrc,
    preview,
  } = props;

  return (
    <StyledPolaroidContainer>
      <StyledPolaroidBorder>
        <StyledPolaroidFrame>
          <StyledPolaroidImgBorder>
            {preview ? (
              <StyledPreviewImg src={imgSrc} maskUrl={innerUrl} />
            ) : (
              <StyledImg
                fluid={{ ...main_image?.childImageSharp.fluid }}
                $aspectRatio={main_image?.childImageSharp.fluid.aspectRatio}
              />
            )}
          </StyledPolaroidImgBorder>
          <StyledTextContainer>
            <StyledCaption
              $aspectRatio={
                main_image?.childImageSharp?.fluid.aspectRatio || null
              }
            >
              {title}
            </StyledCaption>
            <StyledCredit
              href={credit_link}
              target="_blank"
              rel="noopener noreferrer"
              $aspectRatio={
                main_image?.childImageSharp?.fluid.aspectRatio || null
              }
            >
              Photo by {credit}
            </StyledCredit>
          </StyledTextContainer>
        </StyledPolaroidFrame>
      </StyledPolaroidBorder>
      <StyledTape src={tape} $top />
      <StyledTape src={tape} />
    </StyledPolaroidContainer>
  );
};

export default React.memo(Polaroid);
