import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';

import PreviewImg from './shared/PreviewImg';
import { Collections } from '../@types/GraphQL';
import borderUrl from '../assets/images/album_border.svg';
import { ReactComponent as Record } from '../assets/images/album_record.svg';

const StyledAlbumContainer = styled.div`
  position: relative;
  width: 27rem;
  height: 20rem;
`;

const StyledBorder = styled.div`
  width: 20rem;
  ${props => props.theme.borderMask(borderUrl)};
`;

const StyledImg = styled(Img)`
  overflow: hidden;
  ${props => props.theme.mask(borderUrl)};
`;

const StyledRecord = styled(Record)`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  padding: 0.5rem 0;
`;

interface AlbumProps {
  content: Collections.Album;
  imgSrc?: { url: string; path: string; field?: any; fileObj: File };
  preview?: boolean;
}

const Album: React.FC<AlbumProps> = props => {
  const {
    content: { main_image },
    imgSrc,
    preview,
  } = props;

  return (
    <StyledAlbumContainer>
      <StyledRecord />
      <StyledBorder>
        {preview ? (
          <PreviewImg src={imgSrc} maskUrl={borderUrl} />
        ) : (
          <StyledImg fluid={{ ...main_image?.childImageSharp.fluid }} />
        )}
      </StyledBorder>
    </StyledAlbumContainer>
  );
};

export default Album;
