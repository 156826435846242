import React from 'react';
import styled, { css } from 'styled-components';
import Img from 'gatsby-image';

import PreviewImg from '../shared/PreviewImg';

import borderUrl from '../../assets/images/photo_border.svg';
import captionUrl from '../../assets/images/photo_caption.svg';
import creditUrl from '../../assets/images/photo_credit.svg';

const StyledImgBorder = styled.div`
  position: relative;
  ${props => props.theme.borderMask(borderUrl)};
`;

type StyledImgProps = {
  aspectRatio?: number;
  detailView?: boolean;
};

const StyledImg = styled(Img)<StyledImgProps>`
  ${props =>
    props.detailView
      ? css`
          height: 80vh;
          width: calc(${props.aspectRatio} * 80vh);
          max-width: 80vw;

          @media (max-width: ${props => props.theme.breakSmall}) {
            width: 80vw;
            height: calc(80vw / ${props.aspectRatio});
          }
        `
      : css`
          height: 23rem;
          width: calc(${props.aspectRatio} * 23rem);

          @media (max-width: ${props => props.theme.breakMedium}) {
            height: 20rem;
            width: calc(${props.aspectRatio} * 20rem);
            max-width: 80vw;
          }

          @media (max-width: ${props => props.theme.breakSmall}) {
            width: 80vw;
            height: calc(80vw / ${props.aspectRatio});
          }
        `}
  ${props => props.theme.mask(borderUrl)};
`;

const StyledPreviewImg = styled(PreviewImg)<StyledImgProps>`
  height: 23rem;
  width: auto;
`;

type StyledTextContainerProps = {
  position: string;
};

const StyledTextContainer = styled.div<StyledTextContainerProps>`
  position: absolute;
  max-width: 90%;
  ${props => getPositionCSS(props.position)};
`;

const StyledText = styled.span`
  display: inline-block;
  background: ${props => props.theme.white};
  ${props => props.theme.customTextStyle};
`;

const StyledCaptionBorder = styled.div`
  display: inline-block;
  ${props => props.theme.borderMask(captionUrl)};
`;

const StyledCaptionText = styled(StyledText)`
  padding: 0.5rem 0.8rem;
  font-size: 0.875rem;
  ${props => props.theme.mask(captionUrl)};
`;

const StyledCredit = styled.a`
  display: block;
  margin-top: -0.6rem;
  text-decoration: none;
  color: ${props => props.theme.black};
`;

const StyledCreditBorder = styled.div`
  display: inline-block;
  ${props => props.theme.borderMask(creditUrl)};
`;

const StyledCreditText = styled(StyledText)`
  padding: 0.4rem 0.8rem 0.3rem;
  font-size: 0.625rem;
  ${props => props.theme.mask(creditUrl)};
`;

const CreditTopLeft = css`
  top: 5%;
  left: 5%;
`;

const CreditTopRight = css`
  top: 5%;
  right: 5%;
  text-align: right;
`;

const CreditBottomLeft = css`
  bottom: 5%;
  left: 5%;
`;

const CreditBottomRight = css`
  bottom: 5%;
  right: 5%;
  text-align: right;
`;

const getPositionCSS = (position: string) => {
  switch (position) {
    case 'top-right':
      return CreditTopRight;
    case 'bottom-left':
      return CreditBottomLeft;
    case 'bottom-right':
      return CreditBottomRight;
    case 'top-left':
    default:
      return CreditTopLeft;
  }
};

interface PhotoProps {
  content: Collections.Photo;
  imgSrc?: { url: string; path: string; field?: any; fileObj: File };
  preview?: boolean;
  detailView?: boolean;
}

const Photo: React.FC<PhotoProps> = props => {
  const {
    content: { title, credit, credit_link, credit_position, main_image },
    imgSrc,
    preview,
    detailView,
  } = props;

  return (
    <StyledImgBorder>
      {preview ? (
        <StyledPreviewImg src={imgSrc} maskUrl={borderUrl} />
      ) : (
        <StyledImg
          fluid={{ ...main_image?.childImageSharp.fluid }}
          aspectRatio={main_image?.childImageSharp.fluid.aspectRatio}
          detailView={detailView}
        />
      )}
      <StyledTextContainer position={credit_position}>
        <StyledCaptionBorder>
          <StyledCaptionText>{title}</StyledCaptionText>
        </StyledCaptionBorder>
        <StyledCredit
          href={credit_link}
          target="_blank"
          rel="noopener noreferrer"
        >
          <StyledCreditBorder>
            <StyledCreditText>Photo by {credit}</StyledCreditText>
          </StyledCreditBorder>
        </StyledCredit>
      </StyledTextContainer>
    </StyledImgBorder>
  );
};

export default React.memo(Photo);
