import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import Img from 'gatsby-image';
import moment from 'moment';

import PreviewImg from '../shared/PreviewImg';

import borderUrl from '../../assets/images/tape_border.svg';
import labelUrl from '../../assets/images/tape_label.svg';
import windowAreaUrl from '../../assets/images/tape_window_area.svg';
import windowUrl from '../../assets/images/tape_window.svg';
import leftSpoolUrl from '../../assets/images/tape_left_spool.svg';
import rightSpoolUrl from '../../assets/images/tape_right_spool.svg';
import playAreaUrl from '../../assets/images/tape_play_area.svg';
import { ReactComponent as Reel } from '../../assets/images/tape_reel.svg';
import { ReactComponent as Play } from '../../assets/images/tape_play.svg';

const StyledTapeBorder = styled.div`
  ${props => props.theme.borderMask(borderUrl)};
`;

const StyledTape = styled.div`
  position: relative;
  width: 21rem;
  height: 14rem;
  padding: 1.2rem;
  background: ${props => props.theme.white};
  ${props => props.theme.mask(borderUrl)};

  @media (max-width: ${props => props.theme.breakMedium}) {
    width: 18rem;
    height: 12rem;
    padding: 1rem;
  }

  @media (max-width: ${props => props.theme.breakSmall}) {
    width: 15rem;
    height: 10rem;
    padding: 0.8rem;
  }
`;

const StyledLabel = styled.div`
  position: relative;
  width: 100%;
  height: 75%;
  ${props => props.theme.mask(labelUrl)};
`;

const imgStyle = css`
  &:after {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    content: '';
  }
`;

const StyledImg = styled(Img)`
  ${imgStyle}
`;

const StyledPreviewImgWrapper = styled.div`
  ${imgStyle}
`;

const StyledText = styled.span`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  font-size: 0.875rem;

  color: ${props => props.theme.white};
  text-align: center;
  ${props => props.theme.customTextStyle};
  ${props => props.theme.ellipsis};
`;

const StyledTitle = styled(StyledText)`
  top: 1rem;
  font-size: 0.875rem;

  @media (max-width: ${props => props.theme.breakMedium}) {
    top: 0.8rem;
  }

  @media (max-width: ${props => props.theme.breakSmall}) {
    top: 0.6rem;
  }
`;

const StyledDate = styled(StyledText)`
  bottom: 0.9rem;
  font-size: 0.75rem;

  @media (max-width: ${props => props.theme.breakMedium}) {
    bottom: 0.6rem;
  }

  @media (max-width: ${props => props.theme.breakSmall}) {
    bottom: 0.5rem;
  }
`;

const StyledWindowArea = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 38%;
  width: 64%;
  background: ${props => props.theme.white};
  ${props => props.theme.mask(windowAreaUrl)};
`;

const StyledWindowBorder = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  ${props => props.theme.borderMask(windowUrl)};
`;

type StyledWindowProps = {
  preview?: boolean;
};

const StyledWindow = styled.div<StyledWindowProps>`
  height: 1.9rem;
  width: 4.5rem;
  ${props =>
    props.preview &&
    css`
      background: ${props.theme.background1};
    `};
  ${props => props.theme.mask(windowUrl)};

  @media (max-width: ${props => props.theme.breakMedium}) {
    height: 1.75rem;
    width: 4.2rem;
  }

  @media (max-width: ${props => props.theme.breakSmall}) {
    height: 1.6rem;
    width: 3.9rem;
  }
`;

const StyledReel = styled(Reel)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 8rem;
  margin-left: 4rem;

  @media (max-width: ${props => props.theme.breakMedium}) {
    height: 7rem;
    margin-left: 3.5rem;
  }

  @media (max-width: ${props => props.theme.breakSmall}) {
    height: 6rem;
    margin-left: 3rem;
  }
`;

type StyledSpoolBorderProps = {
  spoolUrl: string;
  left?: boolean;
};

const StyledSpoolBorder = styled.div<StyledSpoolBorderProps>`
  position: absolute;
  top: 50%;
  ${props =>
    props.left
      ? css`
          left: 3%;
        `
      : css`
          right: 3%;
        `};
  transform: translateY(-50%);
  ${props => props.theme.borderMask(props.spoolUrl)};
`;

type StyledSpoolProps = {
  spoolUrl: string;
  preview?: boolean;
};

const StyledSpool = styled.div<StyledSpoolProps>`
  height: 2.5rem;
  width: 2.5rem;
  ${props =>
    props.preview &&
    css`
      background: ${props.theme.background1};
    `};
  ${props => props.theme.mask(props.spoolUrl)};

  @media (max-width: ${props => props.theme.breakMedium}) {
    height: 2rem;
    width: 2rem;
  }

  @media (max-width: ${props => props.theme.breakSmall}) {
    height: 1.5rem;
    width: 1.5rem;
  }
`;

const StyledPlayAreaBorder = styled.div`
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  width: 73%;
  margin: 0 auto -1rem;
  ${props => props.theme.borderMask(playAreaUrl)};

  @media (max-width: ${props => props.theme.breakMedium}) {
    margin-bottom: -0.9rem;
  }

  @media (max-width: ${props => props.theme.breakSmall}) {
    margin-bottom: -0.8rem;
  }
`;

const StyledPlayArea = styled.div`
  ${props => props.theme.mask(playAreaUrl)};
  background: ${props => props.theme.white};
  padding: 1rem 2rem 2rem;
  display: flex;
  align-items: flex-start;
  justify-content: center;

  @media (max-width: ${props => props.theme.breakMedium}) {
    padding: 0.8rem 1.6rem 1.6rem;
  }

  @media (max-width: ${props => props.theme.breakSmall}) {
    padding: 0.6rem 1.2rem 1.2rem;
  }
`;

const StyledPlayAreaText = styled.span`
  display: inline-block;
  margin-left: 0.7rem;
  font-size: 0.75rem;
  ${props => props.theme.customTextStyle};
  ${props => props.theme.ellipsis};

  @media (max-width: ${props => props.theme.breakMedium}) {
    margin-left: 0.6rem;
  }

  @media (max-width: ${props => props.theme.breakSmall}) {
    margin-left: 0.5rem;
  }
`;

const StyledPlay = styled(Play)`
  height: 0.7rem;
  width: 0.7rem;
  fill: ${props => props.theme.white};
  stroke: ${props => props.theme.black};
  stroke-width: 3;
`;

interface LiveRecordingProps {
  content: Collections.LiveRecording;
  imgSrc?: { url: string; path: string; field?: any; fileObj: File };
  preview?: boolean;
}

const LiveRecording: React.FC<LiveRecordingProps> = props => {
  const {
    content: { title, subtitle, main_image, show },
    imgSrc,
    preview,
  } = props;

  useEffect(() => {
    if (!preview) {
      Array.from(document.getElementsByClassName('styled-bg')).forEach(
        ele => (ele.style.backgroundColor = document.body.style.backgroundColor)
      );
    }
  }, [preview]);

  return (
    <StyledTapeBorder>
      <StyledTape>
        <StyledLabel>
          {preview ? (
            <StyledPreviewImgWrapper>
              <PreviewImg src={imgSrc} />
            </StyledPreviewImgWrapper>
          ) : (
            <StyledImg fluid={{ ...main_image?.childImageSharp.fluid }} />
          )}
          <StyledTitle>{title}</StyledTitle>
          <StyledWindowArea>
            <StyledWindowBorder>
              <StyledWindow className="styled-bg" preview={preview}>
                <StyledReel />
              </StyledWindow>
            </StyledWindowBorder>
            <StyledSpoolBorder spoolUrl={leftSpoolUrl} left={true}>
              <StyledSpool
                className="styled-bg"
                spoolUrl={leftSpoolUrl}
                preview={preview}
              />
            </StyledSpoolBorder>
            <StyledSpoolBorder spoolUrl={rightSpoolUrl}>
              <StyledSpool
                className="styled-bg"
                spoolUrl={rightSpoolUrl}
                preview={preview}
              />
            </StyledSpoolBorder>
          </StyledWindowArea>
          <StyledDate>{moment(show.date).format("D MMM 'YY")}</StyledDate>
        </StyledLabel>
        <StyledPlayAreaBorder>
          <StyledPlayArea>
            <StyledPlay />
            <StyledPlayAreaText>{subtitle}</StyledPlayAreaText>
          </StyledPlayArea>
        </StyledPlayAreaBorder>
      </StyledTape>
    </StyledTapeBorder>
  );
};

export default React.memo(LiveRecording);
