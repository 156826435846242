import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';

import PreviewImg from '../shared/PreviewImg';

import borderUrl from '../../assets/images/tv_border.svg';
import screenUrl from '../../assets/images/tv_screen.svg';
import { ReactComponent as BigPlayButton } from '../../assets/images/tv_play_button_big.svg';
import { ReactComponent as BigPlayArrow } from '../../assets/images/tv_play_arrow_big.svg';
import { ReactComponent as Plug } from '../../assets/images/tv_plug.svg';

const StyledContainer = styled.div`
  display: flex;
  align-items: flex-end;
`;

const StyledBorder = styled.div`
  ${props => props.theme.borderMask(borderUrl)};
`;

const StyledTvFrame = styled.div`
  display: grid;
  grid-template-rows: 1fr auto;
  width: 37rem;
  height: 23rem;
  padding: 1.2rem 1.8rem;
  background: ${props => props.theme.white};
  ${props => props.theme.mask(borderUrl)};

  @media (max-width: ${props => props.theme.breakMedium}) {
    width: 80vw;
    height: calc((23 / 37) * 80vw);
    padding: 4%;
  }
`;

const StyledScreenBorder = styled.div`
  overflow: hidden;
  position: relative;
  ${props => props.theme.borderMask(screenUrl)};
`;

const StyledScreen = styled(Img)`
  height: 100%;
  ${props => props.theme.mask(screenUrl)};
`;

const StyledPreviewImg = styled(PreviewImg)`
  height: 100%;
`;

const StyledText = styled.div`
  display: block;
  padding: 1rem 1rem 0;
  font-size: 0.875rem;
  white-space: pre-wrap;
  ${props => props.theme.customTextStyle};

  @media (max-width: ${props => props.theme.breakMedium}) {
    padding: 2% 2% 0;
  }
`;

const StyledBigPlayButton = styled(BigPlayButton)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 3.5rem;
  fill: ${props => props.theme.white};
  stroke: ${props => props.theme.black};
  stroke-width: 2.5;
`;

const StyledBigPlayArrow = styled(BigPlayArrow)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 1.5rem;
  fill: ${props => props.theme.white};
  stroke: ${props => props.theme.black};
  stroke-width: 2.2;
`;

const StyledPlug = styled(Plug)`
  width: 4rem;
  margin: 0 0 3.2rem -0.6rem;

  @media (max-width: ${props => props.theme.breakMedium}) {
    width: 10vw;
  }
`;

interface LiveVideoProps {
  content: Collections.LiveVideo;
  imgSrc?: { url: string; path: string; field?: any; fileObj: File };
  preview?: boolean;
}

const LiveVideo: React.FC<LiveVideoProps> = props => {
  const {
    content: { title, main_image },
    imgSrc,
    preview,
  } = props;

  return (
    <StyledContainer>
      <StyledBorder>
        <StyledTvFrame>
          <StyledScreenBorder>
            {preview ? (
              <StyledPreviewImg src={imgSrc} maskUrl={screenUrl} />
            ) : (
              <StyledScreen fluid={{ ...main_image?.childImageSharp.fluid }} />
            )}
            <StyledBigPlayButton />
            <StyledBigPlayArrow />
          </StyledScreenBorder>
          <StyledText>{title}</StyledText>
        </StyledTvFrame>
      </StyledBorder>
      <StyledPlug />
    </StyledContainer>
  );
};

export default React.memo(LiveVideo);
